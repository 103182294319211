import dayjs from 'dayjs';

export const useChart = () => {
  const getFormattedDateFromAggregateLevel = (date: string | Date, aggregateLevel: string): string => {
    switch (aggregateLevel) {
      case 'minute':
        return dayjs(date).format('ddd DD - HH:mm');
      case 'hour':
        return dayjs(date).format('DD/MM - HH') + 'h';
      case 'day':
        return dayjs(date).format('DD/MM/YYYY');
      case 'week':
        const start = dayjs(date).startOf('week');
        const end = dayjs(date).endOf('week');
        return `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`;
      case 'month':
        return dayjs(date).format('MM/YYYY');
      default:
        return '';
    }
  };
  return {
    getFormattedDateFromAggregateLevel,
  };
};
